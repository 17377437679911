import TeamMember from './TeamMember';
import TeamInvite from './TeamInvite';
import {TeamMemberRole} from './TeamMember';
import {sortBy} from '../util/array';

export enum SupportedCountry {
    AU = 'Australia',
    NZ = 'New Zealand'
}

export const {AU, NZ} = SupportedCountry;

export type ProductType =
    | 'TeamAdmin'
    | 'MediaValue'
    | 'Termatico'
    | 'AdvertisingCreative'
    | 'DataEntry';

export type Product = {
    product: ProductType;
    industryIds: string[] | null;
    industries?: string[] | null;
    status: TeamProductStatus;
    metadata?: {trialEnd?: string; hasSelectedIndustries?: boolean} | null;
    country: SupportedCountry;
    hasSelectedIndustries?: boolean | null;
};

export enum TeamProductStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    TRIALING = 'TRIALING'
}

const {INACTIVE, TRIALING} = TeamProductStatus;

export default class Team {
    id: string;
    name: string;
    status: string;
    members: Array<TeamMember>;
    invites: Array<TeamInvite>;
    products: Array<Product>;
    productMap: {
        [C in SupportedCountry]?: {
            [P in ProductType]?: Product;
        };
    };
    trialExpired: boolean;
    createdAt: string;
    updatedAt: string;
    hasTrialTeamName?: boolean;
    requestedNzTrial?: boolean;

    constructor(input: Partial<Team>) {
        for (const key in input) {
            this[key] = input[key];
        }
        this.members = input.members || [];
        this.invites = input.invites || [];
        this.products = input.products || [];
        this.productMap = this.products.reduce((map, product) => {
            map[product.country] ||= {};
            // need to do assign products to a country like this because otherwise typescript cries
            const countryMap = map[product.country];
            if (countryMap) countryMap[product.product] = product;
            return map;
        }, {} as typeof this.productMap);
    }

    /**
     * Helper to check whether you can edit (change role, remove) a member of a team
     *
     * This is useful when complying with the rule of always having one owner on a team
     */
    canEditMember(member: TeamMember) {
        return this.canEditMemberRole(member, 'OWNER') || this.canEditMemberRole(member, 'MEMBER');
    }

    canEditMemberRole(member: TeamMember, role: TeamMemberRole) {
        if (this.id !== member.teamId) return false;

        switch (role) {
            case 'OWNER': {
                const hasMultipleOwners =
                    this.members.filter((member) => member.role === 'OWNER').length > 1;
                return member.role === 'OWNER' && hasMultipleOwners;
            }
            case 'MEMBER':
                return member.role === 'MEMBER';
            default:
                return false;
        }
    }

    //
    // Products

    get australianProducts() {
        return this.productMap.Australia ?? {};
    }

    get newZealandProducts() {
        return this.productMap['New Zealand'] ?? {};
    }

    get hasNoPermissions() {
        return this.products.length === 0 || this.products.every((p) => p.status === INACTIVE);
    }

    get hasMoreThanOneCountry() {
        return (this.hasAusCreative || this.hasAusMediaValue) && this.hasNzCreative;
    }

    get isAdminOnlyTeam() {
        return (
            (this.hasTeamAdmin || this.hasDataEntry || this.hasTermatico) &&
            !this.hasAusMediaValue &&
            !this.hasNzCreative &&
            !this.hasAusCreative
        );
    }

    get hasTeamAdmin() {
        return Boolean(this.productMap.Australia?.TeamAdmin);
    }

    get hasTermatico() {
        return Boolean(this.productMap.Australia?.Termatico);
    }

    get hasAusCreative() {
        const product = this.australianProducts.AdvertisingCreative;
        if (!product || product.status === INACTIVE) return false;
        if (product.status === TRIALING) return (product.industryIds || []).length > 0;
        return true;
    }

    get hasNzCreative() {
        const product = this.newZealandProducts.AdvertisingCreative;
        if (!product || product.status === INACTIVE) return false;
        return true;
    }

    get hasAusMediaValue() {
        const product = this.australianProducts.MediaValue;
        if (!product || product.status === INACTIVE) return false;
        if (product.status === TRIALING) return (product.industryIds || []).length > 0;
        return true;
    }

    get hasDataEntry() {
        return Boolean(this.productMap.Australia?.DataEntry);
    }

    get countriesWithAccess() {
        const activeOrTrialingCustomerProducts = this.products.filter(
            (p) =>
                ['MediaValue', 'AdvertisingCreative'].includes(p.product) &&
                p.status !== TeamProductStatus.INACTIVE
        );
        return [...new Set(activeOrTrialingCustomerProducts.map((p) => p.country))];
    }
    // write test
    // open pr

    get trialEndDate() {
        const longestTrialEndDate = sortBy(
            this.products,
            (pp) => {
                if (pp?.metadata?.trialEnd) return new Date(pp.metadata.trialEnd).getTime();
                return '';
            },
            true
        )[0];

        return longestTrialEndDate?.metadata?.trialEnd;
    }

    get mediaValueIndustryNames() {
        return this.productMap.Australia?.MediaValue?.industries ?? [];
    }

    advertisingCreativeIndustryNames(country: SupportedCountry) {
        return this.productMap[country]?.AdvertisingCreative?.industries ?? [];
    }

    mediaValueIsAllowedIndustry(industryId: string) {
        if (!this.productMap.Australia?.MediaValue) return false;
        const industries = this.productMap.Australia?.MediaValue?.industryIds || [];
        return industries.length === 0 || industries.includes(industryId) || industryId === 'All';
    }
}
